import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FirstSection from "../../Components/v2/FirstSection";
import PermaSection from "../../Components/v2/PermaSection";
import SecondSection from "../../Components/v2/SecondSection";
import RemoteSection from "../../Components/v2/RemoteSection";
// import Header from "../../Layouts/Header";
// import CookieConsent from "../../Components/CookieConsent";
// import FirstSection from "../../Components/FirstSection";
// import WhatWeDo from "../../Components/WhatWeDo";
// import SearchTalent from "../../Components/SearchTalent";
// import WhyUs from "../../Components/WhyUs";
// import WhyTunisia from "../../Components/WhyTunisia";
// import Timelines from "../../Components/Timeline";
// import CallToAction from "../../Components/v1/CallToAction";
// import OurTeam from "../../Components/OurTeam";
// import ContactForm from "../../Components/ContactForm";
// import Footer from "../../Layouts/Footer";
import { FullPage, Slide } from 'react-full-page';
import HybridSection from "../../Components/v2/HybridSection";
import StepsSection from "../../Components/v2/StepsSection";
import DiversitySection from "../../Components/v2/DiversitySection";
import QuickSearch from "../../Components/v2/QuickSearch";
import CareerSection from "../../Components/v2/CarrerSection";
import SignUpSection from "../../Components/v2/SignUpSection";
import ContactSection from "../../Components/v2/ContactSection";
import SayHISection from "../../Components/v2/SayHISection";
import BordersSection from "../../Components/v2/BordersSection";
import TransitionComponenet from "../../Shared/Transition"

const Main = ({ storedTheme, setStoredTheme, ...rest }) => {
  const { t } = useTranslation();
  const [ctop, setCtop] = useState(0)
  const [maxHeight, setMaxHeight] = useState(5000)

  window.addEventListener("scroll", function () {
    const maxHeight = document.body.scrollHeight - window.innerHeight;
    var scrollLocation = (window.pageYOffset * 100) / maxHeight
    console.log(scrollLocation);
    if (scrollLocation >= 25) {
      setCtop(- (scrollLocation - 25) * 10)
      setMaxHeight(5000 - ctop)
    }

  });


  storedTheme = "light"

  return (
    <>
      <FirstSection
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></FirstSection>
      <SecondSection
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></SecondSection>
      <div className="relative" style={{ transform: 'translate(0px, ' + ctop + 'px)', maxHeight: `${maxHeight}px` }} >
        <RemoteSection
          storedTheme={storedTheme}
          setStoredTheme={setStoredTheme}
        ></RemoteSection>
        <PermaSection
          storedTheme={storedTheme}
          setStoredTheme={setStoredTheme}
        ></PermaSection>
        <HybridSection
          storedTheme={storedTheme}
          setStoredTheme={setStoredTheme}
        ></HybridSection>
        <StepsSection
          storedTheme={storedTheme}
          setStoredTheme={setStoredTheme}
        ></StepsSection>
      </div>

      <DiversitySection
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></DiversitySection>
      <QuickSearch
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></QuickSearch>
      <BordersSection
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></BordersSection>

      <div className="py-24 px-4 h-screen lg:w-2/4 text-black lg:ml-12">
        <div className="h-1/2">
          <div className="text-5xl lg:text-5xl font-bold">WE ALL KNOW THAT TUNISIA IS EXPORTER NO.1 OF ORGANIC OLIVE OIL & HAS THE BEST COUS COUS* WORLDWIDE. </div>
          <div className="text-[1.5vh]">*DO NOT READ THIS IF YOU ARE MOROCCAN</div>
        </div>
        <div className="h-1/2">
          <div className="text-5xl lg:text-5xl font-bold">BUT DID YOU KNOW THAT TUNISIA RANKS SECOND WORLDWIDE IN THE PERCENTAGE OF GRADUATES IN TECHNOLOGY, ENGINEERIN AND MATHEMATICS? </div>
        </div>
      </div>

      <CareerSection
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></CareerSection>

      <TransitionComponenet>
        <SignUpSection
          storedTheme={storedTheme}
          setStoredTheme={setStoredTheme}
        ></SignUpSection>
      </TransitionComponenet>

      <ContactSection
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></ContactSection>
      <SayHISection
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></SayHISection>







      {/* <FirstSection
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></FirstSection> */}

      {/* <WhatWeDo
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></WhatWeDo> */}

      {/* <SearchTalent storedTheme={storedTheme}></SearchTalent> */}

      {/* <WhyUs storedTheme={storedTheme} setStoredTheme={setStoredTheme}></WhyUs>

      <WhyTunisia
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></WhyTunisia>

      <Timelines
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></Timelines>

      <CallToAction
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></CallToAction>

      <OurTeam
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></OurTeam>

      <ContactForm
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></ContactForm> */}
    </>
  );
};

export default Main;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import mainImage from "../../../assets/images/nahini/fourth.png";

const PermaSection = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="bg-white sticky h-auto z-10 top-[175px] lg:top-[135px]">
                <hr className="lg:ml-12 lg:mr-12 text-[#231f20] opacity-50 mt-6"></hr>
                <div className="px-4 text-black lg:ml-12 mt-4">
                    <div className="text-4xl font-bold lg:text-7xl">ON-SITE</div>
                    <div className="text-[1.5vh] md:text-[2vh]  mt-4">Need an on-site employee? </div>
                    <div className="text-[1.5vh] md:text-[2vh]">+ choose from a large talent pool.</div>
                    <div className="text-[1.5vh] md:text-[2vh]">+ headhunting of EU resident Tunisians.</div>
                    <div className="text-[1.5vh] md:text-[2vh]">+ support in technical questions by our IT experts.</div>
                    <div className="text-[1.5vh] md:text-[2vh]">+ highly motivated talents.</div>
                    <div className="text-[1.5vh] md:text-[2vh]">+ support througout the whole onboarding process and candidate journey.</div>
                </div>

                <div className="flex mt-5 h-[34rem]">
                    <img className="w-full object-cover" src={mainImage}></img>
                </div>
            </div>

        </>

    );
};

export default PermaSection;

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cookies from "../../assets/images/nahini/cookies.png";
import darkCookies from "../../assets/images/nahini/dark-cookies.png"
import lightBackground from "../../assets/images/nahini/bg5.jpg";

// import "./index.css";

const CookieConsent = ({ storedTheme, setStoredTheme, ...rest }) => {
  const { t } = useTranslation();
  const show = localStorage.getItem("cookiesApproved") == "true"
  //   const backgroundImage =
  //     storedTheme === "dark" ? darkBackground : lightBackground;

  useEffect(() => {
    document.getElementById('accept_cookie').addEventListener('click', () => {
      localStorage.setItem("cookiesApproved", true)
      document.getElementsByClassName('mfp-close')[0].click()
    })

    document.getElementById('reject_cookie').addEventListener('click', () => {
      localStorage.setItem("cookiesApproved", false)
      window.location = "https://google.com"
    })

    document.getElementById('cookie_btn').addEventListener('click', () => {
      window.location = "/cookie"
    })

  }, [])

  return (
    <div
      id="modalShop"
      className={`modal ${!show ? "modal-auto-open" : ''
        } no-padding ${storedTheme == "dark" ? "bg-black text-white" : ""}`}
      data-delay="2000"
      style={{ maxWidth: "600px" }}
    >
      <div className="flex items-center row h-max-md">
        <div
          className="col-md-12 d-none d-sm-block no-padding flex justify-center flex-col"
          style={{
            background: `transparent url('${storedTheme == "dark" ? darkCookies : cookies}') no-repeat scroll center top / cover`,
            height: "470px",
          }}
        >
          <h1 className="h1 mt-5 flex justify-center text-6xl  decoration-8">{t("cookies_title_1")}</h1>
          <h1 className="h1 flex justify-center text-6xl  decoration-8">{t("cookies_title_2")}</h1>
          <p className="flex justify-center px-6 lg:px-9 text-base ">{t("cookies_text")}</p>

          <div className="flex items-center flex-col">
            <div className="flex w-48 justify-between">
              <button className="btn btn-outline px-2" type="button" id="accept_cookie">{t("accept")}</button>
              <button className="btn btn-outline px-2" type="button" id="reject_cookie">{t("reject_all")}</button>
            </div>
            <div className="flex mt-2 w-48">
              <button className="btn btn-outline w-full px-2" id="cookie_btn">{t("cookies_policies")}</button>
            </div>
          </div>
        </div>


      </div>

    </div>
  );
};

export default CookieConsent;
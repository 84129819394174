import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import teamImage1 from "../../../assets/images/nahini/805f3bf6.jpg";
import teamImage2 from "../../../assets/images/nahini/e428e49e.jpg";

const ContactSection = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <div id="contact" className="lg:h-screen py-16 text-black">
                <div className="">
                    <div className="mb-1 ml-4 lg:ml-12 lg:mx-6 text-[2.6vh] lg:text-[3vh]">CONTACT</div>
                    <hr className="lg:ml-12 lg:mr-12 text-[#231f20] mb-3 opacity-50"></hr>

                    <div className="mx-4">
                        <div className="text-5xl lg:text-6xl mt-3 my-2 font-bold lg:ml-12 lg:w-3/6">A CHAT WITH US
                            WILL ONLY COST YOU 30 MINUTES. LONG TALENT SEARCHES, EXPENSIVE RECRUITING PROCESSES AND POSSIBLE MISMATCHES PROBABLY MORE. </div>
                        <div className="mt-3 my-2 text-xl lg:ml-12 lg:w-3/6"> We speak German, French, English, Arabic and Tech.</div>
                    </div>

                </div>
            </div>
        </>

    );
};

export default ContactSection;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import teamImage1 from "../../../assets/images/nahini/805f3bf6.jpg";
import teamImage2 from "../../../assets/images/nahini/e428e49e.jpg";

const BordersSection = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="text-black lg:flex lg:items-center lg:h-screen py-24 px-12 leading-10 text-center lg:justify-center">
                <div className="text-7xl lg:text-9xl font-bold">WE ARE CREATING CHANCES ACROSS BORDERS.</div>
            </div>
        </>

    );
};

export default BordersSection;

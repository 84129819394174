import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import mainImage from "../../../assets/images/nahini/diver.png";

const DiversitySection = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="text-black">
                <div className="px-4 lg:ml-12">
                    <div className="text-[2.5vh] md:text-[4vh]">NO MATTER WHICH MODEL SUITS YOU MOST</div>
                    <div className="text-[4.5vh] mb-3 leading-10 font-bold md:text-7xl">ALL OUR CANDIDATES ARE HIGHLY QUALIFIED</div>
                    <div className="text-[1.5vh] md:text-[2vh]">+ have an Academic degree according to European standards.</div>
                    <div className="text-[1.5vh] md:text-[2vh]">+ their matching potential is identified and technical skills are tested by German IT experts with more than 15 Years of experience.</div>
                    <div className="text-[1.5vh] md:text-[2vh]">+ speak at least 3 languages (Arabic and French at native level and English at full professional proficiency).</div>
                </div>

                <div className="flex mt-5 ">
                    <img className="w-full" src={mainImage}></img>
                </div>
            </div>

        </>

    );
};

export default DiversitySection;

import React from "react";
import { useTranslation } from "react-i18next";


const imprint = ({ storedTheme, setStoredTheme, ...rest }) => {
  const { t } = useTranslation();

  return (
    <>

      <section id="page-title" data-bg-parallax="images/parallax/5.jpg">
        <div className="container">
          <div className="page-title">
            <h1>{t("imprint")}</h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container" style={{ paddingTop: "50px", height: "100%", paddingBottom: "100px" }}>

          {/* <h1 className="h1 mt-2">{t("imprint")}</h1> */}
          <p className="h3">Sahbani Recruting Sarl</p>

          <p>009 place Ibn Hafs</p>
          <p>1002 Tunis</p>
          <p>Tunisia</p><br />
          <p className="h3">{t("CEOs")}</p>
          <p>Sarah Sahbani, Oualid Sahbani</p><br />
          <p>{t("taxNumber")}: 1780902/D</p><br />
          <p className="h3">{t("contact")}:</p>
          <p>+491719370405</p>
          <p>hey@sahbanirecruiting.com</p>
          <p>https://sahbanirecruiting.com</p>

          <br></br>
          <br></br>
          <br></br>

          <h1 className="h3" style={{ textAlign: "bottom" }}>Developed by <a href="https://eskills.tn" target={"_blank"} className="text-orange-400">Eskills.tn</a></h1>

        </div>
      </section>
    </>
  );
};

export default imprint;

import "./App.css";
import React, { useState, useEffect } from "react";

import Header from "./Layouts/Header";
import Footer from "./Layouts/Footer";
import ImageFullscreen from "./Components/ImageFullscreen";
import WhatWeDo from "./Components/WhatWeDo";
import Timelines from "./Components/Timeline";
import CallToAction from "./Components/CallToAction";
import OurClients from "./Components/OurClients";
import OurTeam from "./Components/OurTeam";
import ContactForm from "./Components/ContactForm";
import InspiroSlider from "./Components/InspiroSlider";
import OurActivities from "./Components/OurActivities";
import FirstSection from "./Components/FirstSection";
import WhyUs from "./Components/WhyUs";
import WhyTunisia from "./Components/WhyTunisia";
import SearchTalent from "./Components/SearchTalent";
import CookieConsent from "./Components/CookieConsent";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Main from "./pages/Main";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Cookie from "./pages/cookies";
import Imprint from "./pages/imprint";
import Hamburger from 'hamburger-react'
import LightLogo from "./assets/images/nahini/light-mobile-logo.png"
import DarkLogo from "./assets/images/nahini/dark-mobile-logo.png"
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import LoginLight from "./assets/images/nahini/enter-light.png"
import LoginDark from "./assets/images/nahini/enter-dark.png"

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);


function App() {
  const prefersDark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  const [storedTheme, setStoredTheme] = useState(
    localStorage.getItem("theme") != undefined
      ? localStorage.getItem("theme")
      : "light"
  );

  useEffect(() => {
    const functionsScript = document.createElement("script");
    functionsScript.src = "js/functions.js";
    functionsScript.async = false;

    document.body.appendChild(functionsScript);


    document.addEventListener("touchstart", function () { }, true);

    return () => {
      document.body.removeChild(functionsScript);
    };
  }, []);

  const lightStyle =
    ".heading-text.heading-section > h2::before { background-color: #ff8b2a !important}";
  const darkStyle =
    ".heading-text.heading-section > h2::before { background-color: #adab07 !important} .item-link {color: #adab07} .item-link:hover {color: #adab07} .post-item .post-item-description > h2, .post-item .post-item-description > h2 > a {color: #fff !important} .heading-text.heading-line h1::before, .heading-text.heading-line h2::before, .heading-text.heading-line h3::before, .heading-text.heading-line h4::before, .heading-text.heading-line h5::before, .heading-text.heading-line h6::before { background-color: #adab07 !important}";


  const [isOpen, setOpen] = useState(false)

  const menuOn = { zIndex: 598, transition: "left .5s ease-in-out", left: 0, top: 0 }
  const menuOff = { left: "-200%" }

  const navOn = { borderStyle: "none solid solid none", zIndex: 998, borderColor: "white", backgroundColor: "#fb923c", transition: "background-color .99s linear" }
  const navOff = { borderStyle: "none solid solid none", zIndex: 998, borderColor: "black", backgroundColor: "white" }

  return (
    <>
      <style>{storedTheme == "dark" ? darkStyle : lightStyle}</style>
      <Router>
        {/* <Header
          storedTheme={storedTheme}
          setStoredTheme={setStoredTheme}
        ></Header> */}

        {window.location.toString().includes('cookie') ? <></> : <CookieConsent
          storedTheme={storedTheme}
          setStoredTheme={setStoredTheme}
        ></CookieConsent>}

        <div className={`fixed w-full lg:w-[5.7rem] h-[4.3rem] lg:h-full flex lg:flex-col items-center lg:py-5`} style={isOpen ? navOn : navOff}>
          <div className={`flex lg:flex-col h-full border-b-[1px] lg:border-b-0 ${isOpen ? "lg:border-r-[0px]" : "lg:border-r-[1px]"}  border-opacity-50 ${isOpen ? "border-white" : "border-[#231f20]"}`}>
            <div className="flex items-center lg:items-start lg:justify-center basis-1/3 pl-3 lg:pl-0">
              <Hamburger toggled={isOpen} size={29} toggle={setOpen} color={isOpen ? "white" : "black"} onToggle={toggled => {
                if (toggled) {
                  setOpen(true)
                } else {
                  setOpen(false)
                  // close a menu
                }
              }} />
            </div>
            <div className="flex basis-1/3 justify-center items-center p-[30px] lg:p-0">
              <a href="/"><img src={isOpen ? DarkLogo : LightLogo}></img></a>
            </div>
            <div className="basis-1/3 flex items-center lg:items-end justify-end pr-6 lg:pr-8 lg:mb-2">
              <a href="https://me.sahbanirecruiting.com" target={"_blank"}><img src={isOpen ? LoginLight : LoginDark} className={"h-6 w-7"} alt="Login"></img></a>
            </div>
          </div>


        </div>

        <div className="fixed lg:flex w-screen h-screen bg-orange-400" style={isOpen ? menuOn : menuOff}>
          <div className="text-white lg:pt-12 pt-24 px-3 text-5xl lg:text-9xl lg:ml-24 whitespace-nowrap h-3/4">
            <div className="block cursor-pointer font-bold" onClick={() => setOpen(false)}><a href="/#thisisus">THIS IS US</a></div>
            <div className="cursor-pointer font-bold" onClick={() => setOpen(false)}><a href="/#services">SERVICES</a></div>
            <div className="cursor-pointer font-bold" onClick={() => setOpen(false)}><a href="/#searchTalent">TALENT SEARCH</a></div>
            <div className="cursor-pointer font-bold" onClick={() => setOpen(false)}><a href="/#career">CAREER</a></div>
            <div className="cursor-pointer font-bold" onClick={() => setOpen(false)}><a href="/#signup">SIGN UP</a></div>
            <div className="cursor-pointer font-bold" onClick={() => setOpen(false)}><a href="/#contact">CONTACT</a></div>
          </div>
          <div className={`lg:flex lg:flex-col lg:justify-center lg:w-full`}>
            <div className={`${isOpen ? "lg:fixed" : ""} lg:w-1/6 lg:right-0 lg:mr-16`}>
              <div className="flex justify-center lg:justify-end lg:mr-2">
                <a href="https://www.facebook.com/profile.php?id=100086287887238" target={"_blank"}><div className="w-10 h-10 flex items-center justify-center text-white mx-2 border-1 border-white rounded-full text-xl"><i className="fab fa-facebook-f"></i></div></a>
                <a href="https://www.instagram.com/sr_recruitmentagency/" target={"_blank"}><div className="w-10 h-10 flex items-center justify-center text-white mx-2 border-1 border-white rounded-full text-xl"><i className="fab fa-instagram"></i></div></a>
                <a href="https://www.linkedin.com/company/sahbani-recruiting/" target={"_blank"}><div className="w-10 h-10 flex items-center justify-center text-white mx-2 border-1 border-white rounded-full text-xl"><i className="fab fa-linkedin"></i></div></a>
              </div>
              <div className="flex whitespace-nowrap p-3 text-md text-white lg:justify-end">
                <div className="basis-1/3 lg:hidden"></div>
                <div className="basis-1/3 lg:w-full">
                  <p className="text-sm"><a href="/terms">Terms and Conditions</a></p>
                  <p className="text-sm"><a href="/privacy">Privacy Policy</a></p>
                  <p className="text-sm"><a href="/imprint">Imprint</a></p>
                </div>
                <div className="basis-1/3 lg:hidden"></div>
              </div>
            </div>

          </div>
        </div>


        <div className="flex flex-row w-full">
          {/* <div className="my-4 ml-5 mr-4 p-3 md:p-4 lg:p-4 h-screen fixed border-black border-1" style={{ borderStyle: "none solid none none" }}>SR</div>
          <div className="basis-16 md:basis-24 my-4 hideen "></div> */}
          <div className="basis-full mt-20 lg:ml-[5.6rem] lg:mt-[40px]">
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Routes>
              <Route
                path="/"
                element={
                  <Main
                    storedTheme={storedTheme}
                    setStoredTheme={setStoredTheme}
                  ></Main>
                }
              ></Route>
              <Route path="/terms" element={<Terms />}></Route>
              <Route path="/privacy" element={<Privacy />}></Route>
              <Route path="/cookie" element={<Cookie />}></Route>
              <Route path="/imprint" element={<Imprint />}></Route>
            </Routes>
          </div>
        </div>



        {/* <Footer
          storedTheme={storedTheme}
          setStoredTheme={setStoredTheme}
        ></Footer> */}
      </Router>

      {/* <InspiroSlider></InspiroSlider> */}

      {/* <OurActivities
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></OurActivities> */}
      {/* <OurClients
        storedTheme={storedTheme}
        setStoredTheme={setStoredTheme}
      ></OurClients> */}

      {/*<Timeline></Timeline>
      <CallToAction></CallToAction>
      */}
    </>
  );
}

export default App;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import teamImage1 from "../../../assets/images/nahini/805f3bf6.jpg";
import teamImage2 from "../../../assets/images/nahini/e428e49e.jpg";

const StepsSection = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="bg-white sticky h-auto z-10 top-[360px] text-black">
                <div className="px-4 lg:ml-12 ">
                    {/* <div className="text-[2.5vh] md:text-[4vh]">OUR SERVICES</div> */}
                    <div className="text-4xl pt-24 font-bold lg:text-7xl leading-8 mb-3">WE TECH CARE OF YOUR VACANCY</div>
                    <div className="">
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">1</div>
                            <div className="ml-2 md:text-[2vh]">tell us your requirements.</div>
                        </div>
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">2</div>
                            <div className="ml-2 md:text-[2vh]">we search for suitable candidates.</div>
                        </div>
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">3</div>
                            <div className="ml-2 md:text-[2vh]">our IT experts in Germany will analyze the matching potential.</div>
                        </div>
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">4</div>
                            <div className="ml-2 md:text-[2vh]">offer - choose from a selection of candidates.</div>
                        </div>
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">5</div>
                            <div className="ml-2 md:text-[2vh]">matchmaking.</div>
                        </div>
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">6</div>
                            <div className="ml-2 md:text-[2vh]">onboarding.</div>
                        </div>
                    </div>

                </div>
            </div>

        </>

    );
};

export default StepsSection;

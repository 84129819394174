import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../Layouts/Header";
import CookieConsent from "../../Components/CookieConsent";
import FirstSection from "../../Components/FirstSection";
import WhatWeDo from "../../Components/WhatWeDo";
import SearchTalent from "../../Components/SearchTalent";
import WhyUs from "../../Components/WhyUs";
import WhyTunisia from "../../Components/WhyTunisia";
import Timelines from "../../Components/Timeline";
import CallToAction from "../../Components/CallToAction";
import OurTeam from "../../Components/OurTeam";
import ContactForm from "../../Components/ContactForm";
import Footer from "../../Layouts/Footer";

const Terms = ({ storedTheme, setStoredTheme, ...rest }) => {
  const { t } = useTranslation();

  return (
    <>
      <section id="page-title" data-bg-parallax="images/parallax/5.jpg">
        <div className="container">
          <div className="page-title">
            <h1>Terms and Conditions</h1>
          </div>
        </div>
      </section>

      <section>
        <div className="container p-50">
          {/* <h1 className="h1 mt-2">TERMS AND CONDITIONS</h1> */}
          <p className="text-bold">Last updated April 13, 2023 </p>
          <h3 className="h3">TABLE OF CONTENTS </h3>
          <ol>
            <li><a href="#1">1. {t("term1_title")}</a></li>
            <li><a href="#2">2. {t("term2_title")}</a></li>
            <li><a href="#3">3. {t("term3_title")}</a></li>
            <li><a href="#4">4. {t("term4_title")}</a></li>
            <li><a href="#5">5. {t("term5_title")}</a></li>
            <li><a href="#6">6. {t("term6_title")}</a></li>
            <li><a href="#7">7. {t("term7_title")}</a></li>
            <li><a href="#8">8. {t("term8_title")}</a></li>
            <li><a href="#9">9. {t("term9_title")}</a></li>
            <li><a href="#10">10. {t("term10_title")}</a></li>
            <li><a href="#11">11. {t("term11_title")}</a></li>

          </ol>
          <h1 className="h2 mt-2" id="1">1. {t("term1_title")}</h1>
          <p>{t("term1_content")}</p>
          <h1 className="h2 mt-2" id="2">2. {t("term2_title")}</h1>
          <p>2.1 {t("term2_content_21")}</p>
          <p>2.2 {t("term2_content_22_title")}</p>
          <ul>
            <li> - {t("term2_content_22_item_1")}</li>
            <li> - {t("term2_content_22_item_2")}</li>
            <li> - {t("term2_content_22_item_3")}</li>
          </ul>
          <p>2.3 {t("term2_content_23")}</p>
          <p>2.4 {t("term2_content_24")}</p>
          <h1 className="h2 mt-2" id="3">3. {t("term3_title")}</h1>
          <p>3.1 {t("term3_content_31")}</p>
          <p>3.2 {t("term3_content_32")}</p>
          <p>3.3 {t("term3_content_33")}</p>
          <p>3.4 {t("term3_content_34")}</p>
          <p>3.5 {t("term3_content_35")}</p>
          <p>3.6 {t("term3_content_36")}</p>
          <p>3.7 {t("term3_content_37")}</p>
          <p>3.8 {t("term3_content_38")}</p>
          <h1 className="h2 mt-2" id="4">4. {t("term4_title")}</h1>
          <p>4.1 {t("term4_content_41")}</p>
          <p>4.2 {t("term4_content_42")}</p>
          <p>4.3 {t("term4_content_43")}</p>
          <p>4.4 {t("term4_content_44")}</p>
          <h1 className="h2 mt-2" id="5">5. {t("term5_title")}</h1>
          <p>5.1 {t("term5_content_51")}</p>
          <p>5.2 {t("term5_content_52")}</p>
          <p>5.3 {t("term5_content_53")}</p>
          <p>5.4 {t("term5_content_54")}</p>
          <p>5.5 {t("term5_content_55")}</p>
          <p>5.6 {t("term5_content_56")}</p>
          <p>5.7 {t("term5_content_57")}</p>
          <p>5.8 {t("term5_content_58")}</p>
          <h1 className="h2 mt-2" id="6">6. {t("term6_title")}</h1>
          <p>6.1 {t("term6_content_61")}</p>
          <p>6.2 {t("term6_content_62")}</p>
          <p>6.3 {t("term6_content_63")}</p>
          <p>6.4 {t("term6_content_64")}</p>
          <p>6.5 {t("term6_content_65")}</p>
          <p>6.6 {t("term6_content_66")}</p>
          <h1 className="h2 mt-2" id="7">7. {t("term7_title")}</h1>
          <p>7.1 {t("term7_content_71")}</p>
          <p>7.2 {t("term7_content_72")}</p>
          <p>7.3 {t("term7_content_73")}</p>
          <p>7.4 {t("term7_content_74")}</p>
          <p>7.5 {t("term7_content_75")}</p>
          <h1 className="h2 mt-2" id="8">8. {t("term8_title")}</h1>
          <p>8.1 {t("term8_content_81_title")}</p>
          <ul>
            <li> - {t("term8_content_81_item_1")}</li>
            <li> - {t("term8_content_81_item_2")}</li>
            <li> - {t("term8_content_81_item_3")}</li>
          </ul>
          <p>8.2 {t("term8_content_82")}</p>
          <p>8.3 {t("term8_content_83")}</p>
          <p>8.4 {t("term8_content_84")}</p>
          <p>8.5 {t("term8_content_85")}</p>
          <p>8.6 {t("term8_content_86")}</p>
          <p>8.7 {t("term8_content_87")}</p>
          <h1 className="h2 mt-2" id="9">9. {t("term9_title")}</h1>
          <p>9.1 {t("term9_content_91")}</p>
          <p>9.2 {t("term9_content_92")}</p>
          <p>9.3 {t("term9_content_93")}</p>
          <p>9.4 {t("term9_content_94")}</p>
          <h1 className="h2 mt-2" id="10">10. {t("term10_title")}</h1>
          <p>10.1 {t("term10_content_101")}</p>
          <p>10.2 {t("term10_content_102")}</p>
          <p>10.3 {t("term10_content_103")}</p>
          <p>10.4 {t("term10_content_104")}</p>
          <p>10.5 {t("term10_content_105")}</p>
          <p>10.6 {t("term10_content_106")}</p>
          <p>10.7 {t("term10_content_107")}</p>
          <h1 className="h2 mt-2" id="11">11. {t("term11_title")}</h1>
          <p>11.1 {t("term11_content_111")}</p>
          <p>11.2 {t("term11_content_112")}</p>
          <p>11.3 {t("term11_content_113")}</p>
          <p>11.4 {t("term11_content_114")}</p>
          <p>11.5 {t("term11_content_115")}</p>

        </div>
      </section>
    </>
  );
};

export default Terms;

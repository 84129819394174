import React, { useState, useRef, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import teamImage1 from "../../../assets/images/nahini/805f3bf6.jpg";
import teamImage2 from "../../../assets/images/nahini/e428e49e.jpg";
import mergeGif from "../../../assets/images/nahini/merge.gif";

import { gsap } from 'gsap';

const SignUpSection = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <div id="signup" className="py-16 text-black ">
                <div className="">
                    <div className="mb-1 ml-4 lg:ml-12 lg:mx-6 text-[2.6vh] lg:text-[3vh]">SIGN UP</div>
                    <hr className="lg:ml-12 lg:mr-12 text-[#231f20] mb-3 opacity-50"></hr>

                    <div className="ml-4 mr-4 text-center lg:flex lg:ml-12">
                        <div className="text-3xl lg:text-5xl mt-4 my-2 font-bold lg:mr-2">YOU ARE AN IT TALENT? SIGN UP ON OUR WESBITE & UPLOAD YOUR PROFILE DETAILS.</div>
                        <div className="text-3xl lg:text-5xl mt-4 my-2 font-bold">YOU ARE A COMPANY? SIGN UP ON OUR WESBITE & UPLOAD YOUR VACANCY DETAILS.</div>
                    </div>

                    <div className="flex mt-4 justify-center" >
                        <img className="w-full lg:w-[800px]" src={mergeGif}></img>
                    </div>
                    <div className="text-center">
                        <div className=" text-[2vh] my-4 font-bold mb-5"> We will do the matching. </div>
                        <button
                            className="btn btn-outline m-r-20"
                            onClick={() => {
                                window
                                    .open("http://me.sahbanirecruiting.com/sign-up", "_blank")
                                    .focus();
                            }}
                        >
                            {"Sign up"}
                        </button>
                    </div>
                </div>
            </div>
        </>

    );
};

export default SignUpSection;

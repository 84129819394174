import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import mainImage from "../../../assets/images/nahini/diversity.png";
import TagsAutocomplete from "../../../Shared/TagsAutocomplete";
import { skillsArray } from "../../../Shared/utils"
import axios from "axios";
import placeholder from "../../../assets/images/nahini/defaultuser.png";
import malePlaceholder from "../../../assets/images/nahini/male.png";
import femalePlaceholder from "../../../assets/images/nahini/female.png";



const QuickSearch = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();
    const [showModal, setShowModal] = React.useState(false);
    const [tags, setTags] = React.useState(["Java"])
    const [loading, setLoading] = React.useState(false)
    const [matchedUsers, setMatchedUsers] = React.useState(false)

    const searchForTalents = async () => {
        // tags fyha tags
        console.log(tags);

        setLoading(true)
        await axios.post(`/users/getUsersByRoleAndSignupCompleteAndTags`, tags).then(async (response) => {
            const result = response.data
            setMatchedUsers(result)
            console.log(matchedUsers);
            // axios
            // then
            setLoading(false)
            setShowModal(true)

        });

    }
    return (
        <>
            <div>
                {showModal ? (
                    <>
                        <div
                            className="justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                        >
                            <div className="relative my-8 max-w-7xl">
                                {/*content*/}
                                <div className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full outline-none focus:outline-none ${storedTheme == "dark" ? "bg-[#1e2022]" : "bg-white"}`}>
                                    <div className="hidden z-50 lg:block absolute h5 bg-[#1e2022] -right-4 -top-4 font-bold text-white border-white border-solid border-1 rounded-full py-1 px-2.5 cursor-pointer" onClick={() => {
                                        setShowModal(false)
                                    }}>x</div>

                                    {/*body*/}
                                    <div className="relative p-4 lg:p-4 flex-auto">
                                        {matchedUsers.length == 0 ? <h3 className={` text-center ${storedTheme == "dark" ? "text-white" : ""}`}>{t("noMatches")}</h3> : null}

                                        <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
                                            {matchedUsers.map((value, key) => {
                                                return <div key=
                                                    {key} className={`p-2 pb-3 border-solid border-grey border-1 rounded-lg shadow-md ${storedTheme == "dark" ? "text-white" : ""}`}>
                                                    <div className="flex justify-end text-bold mb-4">{parseInt(value.score)}% Match!</div>
                                                    <div className="flex justify-center">
                                                        <img alt="user image" onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src = placeholder;
                                                        }} src={value.gender == "female" ? femalePlaceholder : malePlaceholder} className="h-24 w-24 lg:h-32 lg:w-32 border-1 p-2 rounded-full ring-2 ring-white"></img>
                                                    </div>
                                                    <div className="flex flex-col mt-3 ">
                                                        <span className="text-center text-bold text-sm lg:text-lg">{value.title}</span>
                                                        {/* <span className="text-center text-bold text-sm lg:text-base">3 {t("years_experience")}</span> */}
                                                    </div>
                                                    <div className="flex justify-center flex-row flex-wrap gap-1 mt-3 text-center">
                                                        {value.skills.map((value, key) => {
                                                            return <span key={key} className="border-slate-300 border-solid border-1 px-3 py-2 rounded-full text-sm hover:bg-amber-600 hover:text-white">{value}</span>
                                                        })}
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                    {/*footer*/}
                                    <div className={`flex items-center justify-center p-1 border-t border-solid border-slate-200 rounded-b ${storedTheme == "dark" ? "text-white" : ""}`}>
                                        <div className="container px-12 py-4">
                                            <div className="row text-center justify-center">
                                                {/* <h3 className="text-dark">{t("search-talent_calltoaction_title")}</h3> */}
                                                {/* <p className="text-dark">{t("search-talent_calltoaction_description")}</p> */}
                                                <p>{t("search-talent_calltoaction_button")}</p>
                                                {/* <button
                          className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm py-3 w-full rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                          type="button"
                        >
                          Contact us to get access
                        </button> */}
                                                <a
                                                    className="btn btn-outline mt-4 max-w-sm"
                                                    target={"_blank"}
                                                    href="https://me.sahbanirecruiting.com/company-sign-up"
                                                >
                                                    {t("search-sign-up")}
                                                </a>
                                                <button className="lg:hidden underline text-sm" onClick={() => {
                                                    setShowModal(false)
                                                }}>{t("close_label")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-[97%] fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}
                <div id="searchTalent" className="py-16 lg:h-screen lg:flex lg:flex-col lg:justify-center text-black">
                    <div className="ml-4 lg:ml-0">
                        {/* <div className="text-[2.5vh] lg:text-[4vh]"></div> */}
                        <div className="mb-1 lg:ml-12 lg:mx-6 text-[2.6vh] lg:text-[3vh]">QUICK SEARCH</div>
                        <hr className="lg:ml-12 lg:mr-12 text-[#231f20] mb-3 opacity-50"></hr>

                        <div className="text-4xl lg:mx-[4.5rem] my-2 font-bold lg:text-6xl">PRETTY SURE THE RIGHT CANDIDATE IS SWIMMING IN OUR POOL</div>
                    </div>
                    <div className="flex text-center justify-center mt-6 p-4 lg:mx-[10rem]">
                        <TagsAutocomplete
                            suggestions={skillsArray}
                            placeholder={t("search-talent_placeholder")}
                            dark={false}
                            setTags={setTags}
                        />
                        <button className={`flex cursor-pointer items-center border-1 rounded-full ml-2 ${storedTheme == "dark" ? "border-white" : "border-black"}`} type="button" onClick={() => searchForTalents()}>
                            {!loading && <i className="fas fa-arrow-right text-orange" style={{ fontSize: "1.3rem", padding: "1.4rem" }}></i>}
                            {loading && <div className="spinner-border m-3 text-orange" role="status">
                                <span className="sr-only">Searching...</span>
                            </div>}
                        </button>
                    </div>
                </div>
            </div>


        </>

    );
};

export default QuickSearch;

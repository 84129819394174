import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import teamImage1 from "../../../assets/images/nahini/805f3bf6.jpg";
import teamImage2 from "../../../assets/images/nahini/e428e49e.jpg";

const SayHISection = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="flex flex-col py-5 h-screen text-center text-black">
                <div className="h-1/6"></div>
                <div className="h-1/6"></div>
                <div className="h-1/6 p-2">
                    <div className=" text-[8vh] font-bold">SAY HI!</div>

                    <button
                        className="btn btn-outline m-r-20"
                        onClick={() => {
                            window
                                .open("https://outlook.office365.com/owa/calendar/businessmeeting@sahbanirecruiting.com/bookings/?fbclid=IwAR2ro_t0AI6--AwgdRWhzBnCw-nZ_FSt3XLpdQQ4aYSjPjkRq7DdpceBMnU", "_blank")
                                .focus();
                        }}
                    >
                        {"Book a meeting"}
                    </button>
                </div>
                <div className="h-1/6"></div>
                <div className="h-1/6"><div>
                    hey@sahbanirecruiting.com<br></br>
                    +49 1719370405<br></br>
                    +216 25 999 776
                </div></div>

                {/* 
                <div className="p-2 h-1/2">
                    
                </div> */}
            </div>
        </>

    );
};

export default SayHISection;

import React, { useState, useLayoutEffect } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import LooksOneRoundedIcon from "@mui/icons-material/LooksOneRounded";
import LooksTwoRoundedIcon from "@mui/icons-material/LooksTwoRounded";
import Looks3RoundedIcon from "@mui/icons-material/Looks3Rounded";
import Looks4RoundedIcon from "@mui/icons-material/Looks4Rounded";
import Looks5RoundedIcon from "@mui/icons-material/Looks5Rounded";
import Looks6RoundedIcon from "@mui/icons-material/Looks6Rounded";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import Typography from "@mui/material/Typography";
import TimelineSeparatorEdit from "./TimelineSeparatorEdit";
import { useTranslation } from "react-i18next";
import { height } from "@mui/system";
import "./timeline.css";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const Timelines = ({ storedTheme, setStoredTheme, ...rest }) => {
  const [width, height] = useWindowSize();
  const { t } = useTranslation();
  const colorToUse = storedTheme == "dark" ? "#ff8b2a" : "#ff8b2a";

  return (
    <section
      id="steps"
      className={`p-t-100 p-b-100 ${storedTheme == "dark" ? "text-light" : "background-grey"
        }`}
      style={{
        backgroundColor: "#1e2022",
      }}
    >
      <div className="container p-50" data-animate="fadeInUp" data-animate-delay="200">
        <div className="heading-text heading-section text-center m-b-50">
          <h2 >
            {t("timeline_title")}
          </h2>
        </div>

        <div className="timeline_container" >
          <Timeline position={width <= 990 ? "right" : "alternate"}>
            <TimelineItem className="timeline_item">
              <TimelineSeparatorEdit>
                <div className="step_container">1</div>
              </TimelineSeparatorEdit>

              <TimelineContent sx={{ py: "12px", px: 2 }} >
                <Typography fontFamily={"Poppins"} variant="h6" component="">
                  <b>{t("timeline1_title")}</b>
                </Typography>
                <div className="step_paragraph">
                  <Typography fontFamily={"Poppins"}>{t("timeline1_paragraph")}</Typography>
                </div>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem className="timeline_item">
              <TimelineSeparatorEdit>
                <div className="step_container">2</div>
              </TimelineSeparatorEdit>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography fontFamily={"Poppins"} variant="h6" component="" className="step_title">
                  <b>{t("timeline2_title")}</b>
                </Typography>
                <div className="step_paragraph">
                  <Typography fontFamily={"Poppins"}>{t("timeline2_paragraph")}</Typography>
                </div>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem className="timeline_item">
              <TimelineSeparatorEdit>
                <div className="step_container">3</div>
              </TimelineSeparatorEdit>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography fontFamily={"Poppins"} variant="h6" component="" className="step_title">
                  <b>{t("timeline3_title")}</b>
                </Typography>
                <div className="step_paragraph">
                  <Typography fontFamily={"Poppins"}>{t("timeline3_paragraph")}</Typography>
                </div>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem className="timeline_item">
              <TimelineSeparatorEdit>
                <div className="step_container">4</div>
              </TimelineSeparatorEdit>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography fontFamily={"Poppins"} variant="h6" component="" className="step_title">
                  <b>{t("timeline4_title")}</b>
                </Typography>
                <div className="step_paragraph">
                  <Typography fontFamily={"Poppins"}>{t("timeline4_paragraph")}</Typography>
                </div>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem className="timeline_item">
              <TimelineSeparatorEdit>
                <div className="step_container">5</div>
              </TimelineSeparatorEdit>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography fontFamily={"Poppins"} variant="h6" component="" className="step_title">
                  <b>{t("timeline5_title")}</b>
                </Typography>
                <div className="step_paragraph">
                  <Typography fontFamily={"Poppins"}>{t("timeline5_paragraph")}</Typography>
                </div>
              </TimelineContent>
            </TimelineItem>

            <TimelineItem className="timeline_item">
              <TimelineSeparatorEdit>
                <div className="step_container">6</div>
              </TimelineSeparatorEdit>
              <TimelineContent sx={{ py: "12px", px: 2 }}>
                <Typography fontFamily={"Poppins"} variant="h6" component="" className="step_title">
                  <b>{t("timeline6_title")}</b>
                </Typography>
                <div className="step_paragraph">
                  <Typography fontFamily={"Poppins"}>{t("timeline6_paragraph")}</Typography>
                </div>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </div>
      </div>
    </section>
  );
};

export default Timelines;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import mainImage from "../../../assets/images/nahini/fifth.png";

const HybridSection = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="bg-white sticky h-auto mt-6 z-10 top-[245px] lg:top-[240px]">
                <hr className="lg:ml-12 lg:mr-12 text-[#231f20] opacity-50 mt-6"></hr>
                <div className="px-4 text-black lg:ml-12  mt-2">
                    <div className="text-[3.5vh] font-bold lg:text-[7.5vh] whitespace-nowrap">HYBRID</div>
                    <div className="text-[1.5vh] md:text-[2vh]">Want to be sure that the candidate is the right one? Or need someone immediately? Start hiring in Remote for a short term assignment and connect with on-site employment.</div>
                    <div className="text-[1.5vh] md:text-[2vh]">+ time efficient.</div>
                    <div className="text-[1.5vh] md:text-[2vh]">+ get to know your candidate before you hire permanently.</div>
                    <div className="text-[1.5vh] md:text-[2vh]">+ combines the advantages of both models - remote and on-site placement.</div>
                </div>

                <div className="flex mt-5 h-[34rem]">
                    <img className="w-full object-cover" src={mainImage}></img>
                </div>
                <hr className="lg:ml-12 lg:mr-12 text-[#231f20] opacity-50 mt-6"></hr>

            </div>
        </>

    );
};

export default HybridSection;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import teamImage1 from "../../../assets/images/nahini/805f3bf6.jpg";
import teamImage2 from "../../../assets/images/nahini/e428e49e.jpg";
import mainImage from "../../../assets/images/nahini/remote.jpg";

const RemoteSection = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <div id="services" className="bg-white sticky h-auto z-10 top-20 lg:top-0">
                <div className="mb-1 ml-6 lg:ml-12 lg:mx-6 text-[2.6vh] lg:text-[3vh]">OUR SERVICES</div>
                <hr className="lg:ml-12 lg:mr-12 text-[#231f20] opacity-50"></hr>
                <div className="px-4 text-black lg:ml-12">
                    <div className="text-[3.5vh] font-bold lg:text-7xl mt-2 lg:mt-4">REMOTE</div>
                    <div className="text-[1.5vh] lg:text-[2vh] mt-4"> No need of an on-site employee? </div>
                    <div className="text-[1.5vh] lg:text-[2vh]">+ cost-effective.</div>
                    <div className="text-[1.5vh] lg:text-[2vh]">+ short or longterm assignments possible.</div>
                    <div className="text-[1.5vh] lg:text-[2vh]">+ possibility of employing staff abroad without having to open an entity.</div>
                    <div className="text-[1.5vh] lg:text-[2vh]">+ hardware will be provided by us.</div>
                    <div className="text-[1.5vh] lg:text-[2vh]">+ compliant: the IT talents are employed by us on a compulsory social basis.</div>
                    <div className="text-[1.5vh] lg:text-[2vh]">+ nearshore: same time zone.</div>
                    <div className="text-[1.5vh] lg:text-[2vh]">+ highly motivated candidate: can stay with family/friends and is paid above average.</div>
                    <div className="text-[1.5vh] lg:text-[2vh]">+ sustainable: have a positive impact on Tunisia's future by counteracting the brain drain.</div>
                </div>

                <div className="flex mt-5 h-[34rem]">
                    <img className="w-full object-cover" src={mainImage}></img>
                </div>
            </div>

        </>

    );
};

export default RemoteSection;

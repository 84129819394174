import React, { Component, useEffect, useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import contactBg from "../../assets/images/nahini/contact-bg.png";
import Autocomplete from "../../Shared/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import MuiPhoneNumber from 'material-ui-phone-number';
// jsx-a11y/anchor-is-valid
import axios from "axios";
import { phone } from 'phone';

axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? "http://localhost:3001/v1/" : "/v1/";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Content-Type"] =
  "application/x-www-form-urlencoded";

const ContactForm = ({ storedTheme, setStoredTheme, ...rest }) => {
  const { t } = useTranslation();

  const setDark = () => {
    setStoredTheme("dark");
    localStorage.setItem("theme", "dark");
    //document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    setStoredTheme("light");
    localStorage.setItem("theme", "light");
    //document.documentElement.setAttribute("data-theme", "light");
  };

  const toggleTheme = (e) => {
    if (storedTheme === "light") {
      setDark();
    } else {
      setLight();
    }
  };

  useEffect(() => {
    localStorage.setItem("theme", storedTheme);
  }, []);


  const handleChange = (data) => {
    setValue('PhoneNum', data)
  }


  const { register, handleSubmit, setValue, control, reset, formState: { errors } } = useForm({
    mode: "onChange",
    defaultValues: {
      CompanyName: '',
      Email: '',
      Subject: '',
      PhoneNum: '',
      Message: ''
    }
  });

  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);

  const submitForm = async (data) => {
    setShow(true)
    const email = {
      "to": data.Email,
      "subject": data.Subject,
      "text": "Company Name: " + data.CompanyName + "\n" + "Email: " + data.Email + "\n" + "Phone number: " + data.PhoneNum + "\n" + "Message: " + data.Message
    }

    await axios.post(`/auth/send-email`, email).then((response) => {
      if (response.status == 204) {
        setShow(false)
        setSuccess(true)
        setValue('PhoneNum', '')
        reset()
      }
    });
  };




  return (
    <section
      id="contact"
      className={`p-t-150 p-b-200`}
      style={
        storedTheme === "dark"
          ? {
            backgroundColor: "black",
          }
          : {
            backgroundImage: `url(${contactBg})`,
            backgroundPosition: "71% 22%",
          }
      }
    >
      <div className="container">
        <div className="row">
          <div className={`col-lg-6 ${storedTheme == "dark" ? "text-light" : ""}`}>
            <div className="row">
              <div className="col-lg-12">
                <h2 className="m-b-10 font-bold text-xl">{t("contact_title")}</h2>
                <p className="lead text-base">{t("contact_sub_title")}</p>
              </div>
              <div className="col-lg-6 m-b-30">
                <address>
                  <strong>{t("headquarters")}:</strong> 009, Place Ibn Hafs; Mutuelleville<br></br>
                  <strong>{t("email")}:</strong> hey@sahbanirecruiting.com
                </address>

              </div>
              <div className="col-lg-6 m-b-30">

                <strong>{t("phoneTN")}:</strong> +(216) 25 999 776
                <br></br>
                <strong>{t("phoneGR")}:</strong> +(49) 1719370405
              </div>
              <div className="col-lg-12 m-b-30">
                <h4>{t("we_are_social")}</h4>
                <div className="social-icons social-icons-light social-icons-colored-hover">
                  <ul>

                    <li className="social-facebook">
                      <a
                        href="https://www.facebook.com/profile.php?id=100086287887238"
                        target={"_blank"}
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>

                    <li className="social-instagram">
                      <a
                        href="https://www.instagram.com/sr_recruitmentagency/"
                        target={"_blank"}
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>

                    <li className="social-linkedin">
                      <a
                        href="https://www.linkedin.com/company/sahbani-recruiting/"
                        target={"_blank"}
                      >
                        {" "}
                        <i className="fab fa-linkedin"></i>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <form onSubmit={handleSubmit(submitForm)} id="contact">
              <div className="row">
                <div className="form-group col-md-6">
                  <label className={`${storedTheme == "dark" ? "text-light" : ""}`} htmlFor="name">{t("company_name")}</label>

                  <input
                    type="text"
                    name="CompanyName"
                    className="form-control required name"
                    placeholder={t("company_name_label")}
                    {...register("CompanyName", {
                      required: t('errorCampanyName')
                    })}
                    required
                  />
                  <p className={`text-red`}>{errors.CompanyName && t('errorCampanyName')}

                  </p>
                </div>
                <div className="form-group col-md-6">
                  <label className={`${storedTheme == "dark" ? "text-light" : ""}`} htmlFor="email">{t("email")}</label>
                  <input
                    type="email"
                    name="Email"
                    className="form-control required email"
                    placeholder={t("email_label")}
                    required
                    {...register("Email", {
                      required: t('errorEmail')
                    })}
                  />
                  <p className={`text-red`}>{errors.Email && t('errorEmail')}</p>
                </div>
              </div>

              <div className="form-group">
                <label className={`${storedTheme == "dark" ? "text-light" : ""}`} htmlFor="message">{t('subject_label')}</label>
                <input
                  type="text"
                  name="Subject"
                  className="form-control required email"
                  placeholder={t('subject_label')}
                  required
                  {...register("Subject", {
                    required: t('errorsubject')
                  })}
                />
                <p className={`text-red`}>{errors.Subject && t('errorsubject')}</p>
              </div>

              {/* TODO: ADD translation */}

              <div className="form-group">
                <label className={`${storedTheme == "dark" ? "text-light" : ""}`} htmlFor="message">{t('phone_label')}</label>

                <MuiPhoneNumber
                  {...register("PhoneNum", {
                    required: errors.PhoneNum,
                    validate: (val) => {
                      return (phone(val) && val.length >= 7)
                    }
                  })}
                  onChange={handleChange}
                  className="form-control required"
                  defaultCountry={"tn"}
                  required
                />

                <p className={`text-red`}>{errors.PhoneNum && t('errortel')}</p>
              </div>

              <div className="form-group">
                <label className={`${storedTheme == "dark" ? "text-light" : ""}`} htmlFor="message">{t("message")}</label>
                <textarea
                  type="text"
                  name="Message"
                  rows="8"
                  className="form-control required"
                  placeholder={t("message_placeholder")}
                  {...register("Message", {
                    required: t('errormsg')
                  })}
                  required
                ></textarea>
                <p className={`text-red`}>{errors.Message && t('errormsg')}</p>
              </div>

              <div className="form-group">
                <button
                  className="btn btn-light"
                  type="submit"
                  id="form-submit"
                  style={
                    storedTheme === "dark"
                      ? { color: "red !important" }
                      : { color: "blue !important" }
                  }
                >
                  {show ? <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"> </span> : null}

                  {t("send_message")}
                </button>
              </div>

            </form>
            {success ? <div className={`${storedTheme == "dark" ? "text-light" : ""}`}>{t('successMessage')}</div> : null}

          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import teamImage1 from "../../../assets/images/nahini/805f3bf6.jpg";
import teamImage2 from "../../../assets/images/nahini/e428e49e.jpg";

const CareerSection = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <div id="career" className="py-16 text-black">
                <div className="text-[2.6vh] lg:text-[3vh] mt-[4px] px-4 my-2 lg:mx-6 lg:mt-[40px]">CAREER</div>
                <hr className="lg:ml-12 lg:mr-12 text-[#231f20] opacity-50"></hr>
                <div className="px-4">
                    <div className="text-[4.5vh] lg:text-7xl mt-4 leading-10 font-bold lg:ml-12">LET US BOOST YOUR CAREER IN JUST A FEW STEPS</div>
                    <div className="my-4 lg:ml-12">
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">1</div>
                            <div className="ml-2">application on our website.</div>
                        </div>
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">2</div>
                            <div className="ml-2">CV screening.</div>
                        </div>
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">3</div>
                            <div className="ml-2">language skill check.</div>
                        </div>
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">4</div>
                            <div className="ml-2">technical skill check.</div>
                        </div>
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">5</div>
                            <div className="ml-2">matchmaking.</div>
                        </div>
                        <div className="my-2 flex flex-row items-center">
                            <div className="w-10 h-10 flex items-center justify-center border-1 border-black rounded-full px-3 py-1.5">6</div>
                            <div className="ml-2">onboarding.</div>
                        </div>
                    </div>

                </div>
            </div>

        </>

    );
};

export default CareerSection;

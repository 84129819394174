import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import teamImage1 from "../../../assets/images/nahini/805f3bf6.jpg";
import teamImage2 from "../../../assets/images/nahini/e428e49e.jpg";

const FirstSection = ({ storedTheme, setStoredTheme, ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <div id="thisisus" className="lg:h-screen px-4 text-black">
                <div className="lg:px-5 lg:h-3/6">
                    <div className="text-[2.6vh] lg:text-[3vh] mb-3">THIS IS US</div>
                    <div className="text-[4.5vh] lg:ml-8 lg:text-[7.5vh] my-2 leading-10 lg:leading-[1] lg:w-4/6 font-bold">WE ARE AN IT RECRUITMENT AGENCY BASED IN TUNIS THAT CONNECTS SKILLED IT PROFESSIONALS WITH COMPANIES IN EUROPE.</div>
                    <div className="text-[1.5vh] lg:text-[2] mb-4 lg:ml-8">We are offering recruitment solutions for any need.</div>

                </div>

                <div className="flex flex-col lg:h-2/6 lg:justify-end mt-6 lg:ml-14">
                    <div className="flex lg:w-1/4">
                        <div className="basis-1/2 flex flex-col justify-center items-center text-center">
                            <img className="w-36 rounded-full filter grayscale" src={teamImage1} />
                            <p>Oualid Sahbani</p>
                            <p>{t("co_founder_male")}</p>
                        </div>
                        <div className="basis-1/2 flex flex-col justify-center items-center text-center">
                            <img className="w-36 rounded-full filter grayscale" src={teamImage2} />
                            <p>Sarah Sahbani</p>
                            <p>CEO & Co-Founder</p>
                        </div>
                    </div>

                </div>
            </div>

        </>

    );
};

export default FirstSection;
